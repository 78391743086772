<template>
	<div class="page">
		<div class="box">
			<div class="btn" :class="quickBtnIndex === 0 ? 'active' : ''" @click="changeQuickBtnIndex(0)">本日</div>
			<div class="btn" :class="quickBtnIndex === 1 ? 'active' : ''" @click="changeQuickBtnIndex(1)">本月</div>
			<div class="btn" :class="quickBtnIndex === 2 ? 'active' : ''" @click="changeQuickBtnIndex(2)">本年</div>
			<div>
				<el-date-picker v-model="daterange" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" @change="changeDate">
				</el-date-picker>
			</div>
		</div>
		<div class="box" style="height: 102px;margin-top: 16px;">
			<div class="item" style="border:0;">
				流水
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.currentAccount.money"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
			<div class="item">
				退款金额
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.refundMoney.money"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
			<div class="item">
				自用商品金额
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.oneSelf.money"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
			<div class="item">
				会员总数
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.vipCount"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
			<div class="item">
				门店负债
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.liabilities.money"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
		</div>
		<div class="box" style="height: 102px;margin-top: 16px;">
			<div class="item" style="border:0;">
				订单数
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.orderCount"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
			<div class="item">
				退货单数
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.refundOrderCount"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
			<div class="item">
				客单价
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.perCustomer.money"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
			<div class="item">
				进店会员数
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.enterVipCount"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
			<div class="item">
				新增会员数
				<div class="num">
					<div v-if="!state">0</div>
					<animate-number v-else from="0" :to="result.addVipCount"
						:formatter="(num) => { return num.toFixed(2) }"></animate-number>
				</div>
			</div>
		</div>
		<div class="panel">
			<div class="panelL">
				<div style="display: flex;margin-top: 10px;">
					<div class="tabI" :class="tab === 0 ? 'active' : ''" @click="changeTab(0)">总销售额</div>
					<div class="tabI" :class="tab === 1 ? 'active' : ''" @click="changeTab(1)">流水</div>
					<div class="tabI" :class="tab === 2 ? 'active' : ''" @click="changeTab(2)">营业额</div>
				</div>
				<div id="myChart"></div>
				<div v-if="tab === 0"
					style="position: absolute;width:160px;height: 160px;border-radius: 50%;background-color: #fff;top:142px;left:121px;text-align: center;font-size: 20px;line-height: 40px;">
					<div style="padding-top:35px;color:#7c7d7c;">总销售额</div>
					<div style="font-size: 30px;color:#1a1a1a;font-weight: bold;">
						{{ $public.jia(result.goodsOrderMoney.money, result.serviceOrderMoney.money,
				result.virtualOrderMoney.money, result.goodsGroupOrderMoney.money) }}
					</div>
				</div>
				<div v-if="tab === 1"
					style="position: absolute;width:160px;height: 160px;border-radius: 50%;background-color: #fff;top:142px;left:121px;text-align: center;font-size: 20px;line-height: 40px;">
					<div style="padding-top:35px;color:#7c7d7c;">流水</div>
					<div style="font-size: 30px;color:#1a1a1a;font-weight: bold;">
						{{ $public.jia(result.caseData.alipayMoney.money, result.caseData.caseMoney.money,
				result.caseData.posMoney.money, result.caseData.thirdMoney.money, result.caseData.weiXinMoney.money) }}
					</div>
				</div>
				<div v-if="tab === 2"
					style="position: absolute;width:160px;height: 160px;border-radius: 50%;background-color: #fff;top:142px;left:121px;text-align: center;font-size: 20px;line-height: 40px;">
					<div style="padding-top:35px;color:#7c7d7c;">营业额</div>
					<div style="font-size: 30px;color:#1a1a1a;font-weight: bold;">
						{{ $public.jia(result.customData.alipayMoney.money, result.customData.caseMoney.money,
				result.customData.thirdMoney.money, result.customData.posMoney.money,
				result.customData.virtualMoney.money, result.customData.weiXinMoney.money,
				result.customData.yueMoney.money) }}
					</div>
				</div>
			</div>
			<div class="panelR">
				<div class="tit">爆款商品</div>
				<div class="panelTab">
					<div style="display: flex;">
						<div class="tabItem" :class="tabIndex === 0 ? 'active' : ''" @click="changeTabIndex(0)">日榜</div>
						<div class="tabItem" :class="tabIndex === 1 ? 'active' : ''" @click="changeTabIndex(1)">周榜</div>
						<div class="tabItem" :class="tabIndex === 2 ? 'active' : ''" @click="changeTabIndex(2)">月榜</div>
						<div class="tabItem" :class="tabIndex === 3 ? 'active' : ''" @click="changeTabIndex(3)">季榜</div>
					</div>
					<div style="padding-top:8px;">
						<el-radio-group v-model="radio" @change="changeRadio">
							<el-radio :label="0">按销量</el-radio>
							<el-radio :label="1">按金额</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="panelTable">
					<el-table :data="tableData" height="100%">
						<el-table-column align="center" min-width="50" label="排名">
							<template slot-scope="scope">
								<span v-if="scope.$index === 0"><img style="width:40px;"
										src="../../../assets/img/shuju/jin.png" /></span>
								<span v-else-if="scope.$index === 1"><img style="width:40px;"
										src="../../../assets/img/shuju/yin.png" /></span>
								<span v-else-if="scope.$index === 2"><img style="width:40px;"
										src="../../../assets/img/shuju/tong.png" /></span>
								<span v-else>{{ scope.$index + 1 }}</span>
							</template>
						</el-table-column>
						<el-table-column min-width="100" label="名称">
							<template slot-scope="scope">
								{{ scope.row.goodsName }}
							</template>
						</el-table-column>
						<el-table-column min-width="100" label="销量">
							<template slot-scope="scope">
								{{ scope.row.salesCount }}
							</template>
						</el-table-column>
						<el-table-column min-width="100" label="金额">
							<template slot-scope="scope">
								{{ scope.row.salesMoney.money }}
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import * as echarts from 'echarts';
export default {
	data() {
		return {
			quickBtnIndex: -1,
			daterange: [],
			tabIndex: 0,
			radio: 0,
			tableData: [],
			tab: 0,
			state: false,
			result: {
				caseData: {},
				customData: {},
				currentAccount: {},
				daySales: {},
				goodsGroupOrderMoney: {},
				goodsOrderMoney: {},
				liabilities: {},
				monthSales: {},
				oneSelf: {},
				perCustomer: {},
				quarterSales: {},
				refundMoney: {},
				serviceOrderMoney: {},
				sumOrderMoney: {},
				virtualOrderMoney: {},
				weekSales: {},
				caseMoney: {},
				yueMoney: {},
				alipayMoney: {},
				weiXinMoney: {},
				posMoney: {},
				thirdMoney: {}
			},
			myChart: null,
		}
	},
	computed: {

	},
	mounted() {
		this.myChart = echarts.init(document.getElementById("myChart"));
		this.changeQuickBtnIndex(0)
	},
	methods: {
		changeQuickBtnIndex(i) {
			this.quickBtnIndex = i
			let d = new Date();
			let year = d.getFullYear(), month = d.getMonth(), day = d.getDate()
			if (i === 0) {
				this.daterange = [new Date(year + '-' + (month + 1) + '-' + day), new Date(d.getTime() + 86400000)]
			} else if (i === 1) {
				this.daterange = [new Date(year + '-' + (month + 1) + '-1'), new Date(d.getTime() + 86400000)]
			} else if (i === 2) {
				this.daterange = [new Date(year + '-1-1'), new Date(d.getTime() + 86400000)]
			}
			let time1 = this.$public.FTimeStr10(this.daterange[0])
			let time2 = this.$public.FTimeStr10(this.daterange[1])
			this.getData(time1, time2)
		},
		changeDate(val) {
			this.quickBtnIndex = -1;
			let time1 = this.$public.FTimeStr10(val[0])
			let time2 = this.$public.FTimeStr10(val[1])
			this.getData(time1, time2)
		},
		changeTabIndex(i) {
			this.tabIndex = i;
			this.getTableData()
		},
		changeTab(i) {
			this.tab = i;
			this.drawAll(this.myChart)
		},
		changeRadio() {
			this.getTableData()
		},
		getData(time1, time2) {
			this.state = false;
			let params = {
				StartTime: time1,
				EndTime: time2,
			}
			let loading = this.$loading()
			this.$http.get("/order/Static/GetShopStatic", params).then(res => {
				loading.close()
				if (res.code === 0) {
					this.state = true;
					this.result = res.data
					this.getTableData()
					this.drawAll(this.myChart)
				}
			})
		},
		getTableData() {
			if (this.tabIndex === 0) {
				if (this.radio === 0) {
					this.tableData = this.result.daySales.countList
				} else if (this.radio === 1) {
					this.tableData = this.result.daySales.moneyList
				}
			} else if (this.tabIndex === 1) {
				if (this.radio === 0) {
					this.tableData = this.result.weekSales.countList
				} else if (this.radio === 1) {
					this.tableData = this.result.weekSales.moneyList
				}
			} else if (this.tabIndex === 2) {
				if (this.radio === 0) {
					this.tableData = this.result.monthSales.countList
				} else if (this.radio === 1) {
					this.tableData = this.result.monthSales.moneyList
				}
			} else if (this.tabIndex === 3) {
				if (this.radio === 0) {
					this.tableData = this.result.quarterSales.countList
				} else if (this.radio === 1) {
					this.tableData = this.result.quarterSales.moneyList
				}
			}
		},
		drawAll(chart) {
			let arr = [];
			if (this.tab == 0) {
				arr.push({ value: this.result.goodsOrderMoney.money, name: '商品类' })
				arr.push({ value: this.result.serviceOrderMoney.money, name: '服务类' })
				arr.push({ value: this.result.virtualOrderMoney.money, name: '虚拟类' })
				arr.push({ value: this.result.goodsGroupOrderMoney.money, name: '套餐类' })
			} else if (this.tab == 1) {
				arr.push({ value: this.result.caseData.alipayMoney.money, name: '支付宝' })
				arr.push({ value: this.result.caseData.caseMoney.money, name: '现金' })
				arr.push({ value: this.result.caseData.posMoney.money, name: 'POS' })
				arr.push({ value: this.result.caseData.thirdMoney.money, name: '第三方' })
				arr.push({ value: this.result.caseData.weiXinMoney.money, name: '微信' })
			} else if (this.tab == 2) {
				arr.push({ value: this.result.customData.alipayMoney.money, name: '支付宝' })
				arr.push({ value: this.result.customData.caseMoney.money, name: '现金' })
				arr.push({ value: this.result.customData.posMoney.money, name: 'POS' })
				arr.push({ value: this.result.customData.thirdMoney.money, name: '第三方' })
				arr.push({ value: this.result.customData.weiXinMoney.money, name: '微信' })
				arr.push({ value: this.result.customData.virtualMoney.money, name: '消费卡' })
				arr.push({ value: this.result.customData.yueMoney.money, name: '余额' })
			}
			chart.setOption({
				color: ['#FF6A00', '#FFD74D', '#01C1B2', '#FF445F', '#8167F5', '#F5EC67', '#28D3DB', '#96D9FF'],
				tooltip: {
					trigger: 'item'
				},
				legend: {
					orient: 'vertical',
					right: '30',
					top: '60',
					textStyle: {
						fontSize: '14',
						color: '#666',
						lineHeight: '45'
					},
					formatter: function (e) {
						for (let i = 0; i < arr.length; i++) {
							if (arr[i].name === e) {
								return e + ' ' + arr[i].value
							}
						}
					},
				},
				series: [
					{
						name: '',
						type: 'pie',
						radius: ['31%', '56%'],
						center: [170, 170],
						label: {
							position: 'inner',
							fontSize: 14,
							formatter: (val) => {
								return val.percent + '%';
							}
						},
						data: arr,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						tooltip: {
						}
					},
				]
			})
		},
	}
}
</script>
<style lang="scss" scoped>
.page {
	width: 100%;
	height: 100%;

	.box {
		display: flex;
		padding: 17px 20px;
		background-color: #fff;
		border-radius: 20px;
		box-sizing: border-box;

		.btn {
			width: 110px;
			height: 40px;
			line-height: 38px;
			border: 1px solid #DAD7D7;
			border-radius: 20px;
			text-align: center;
			cursor: pointer;
			font-size: 14px;
			color: #666;
			box-sizing: border-box;
			margin-right: 20px;
			;

			&.active {
				border-color: $mainColor;
				color: $mainColor;
			}
		}

		.item {
			text-align: center;
			font-size: 16px;
			color: $fontColor;
			border-left: 1px solid #D1D1D1;
			width: 100%;
			line-height: 30px;

			.num {
				font-size: 20px;
			}
		}
	}

	.panel {
		color: $fontColor;
		display: flex;
		margin-top: 16px;
		height: calc(100% - 326px);

		.panelL {
			width: 597px;
			height: 100%;
			background-color: #fff;
			box-sizing: border-box;
			border-radius: 20px;
			flex-shrink: 0;
			margin-right: 16px;
			position: relative;

			#myChart {
				width: 100%;
				height: 300px;
				padding: 0 30px;
				box-sizing: border-box;
				height: calc(100% - 67px);
			}

			.tabI {
				font-size: 20px;
				width: 80px;
				border-radius: 20px;
				line-height: 40px;
				font-size: 16px;
				color: $fontColor;
				text-align: center;
				cursor: pointer;

				&.active {
					background-color: #FFF1E2;
					color: $mainColor;
				}
			}



			#myChart1 {
				width: 100%;
				height: 300px;
				padding: 0 30px;
				box-sizing: border-box;
				height: calc(100% - 67px);
			}

			#myChart2 {
				width: 100%;
				height: 300px;
				padding: 0 30px;
				box-sizing: border-box;
				height: calc(100% - 67px);
			}

		}

		.panelR {
			width: calc(100% - 613px);
			height: 100%;
			background-color: #fff;
			box-sizing: border-box;
			border-radius: 20px;
			overflow: hidden;

			.tit {
				font-size: 20px;
				padding: 20px 32px;
			}

			.panelTab {
				display: flex;
				justify-content: space-between;
				margin-bottom: 5px;
				padding: 0 22px;

				.tabItem {
					width: 80px;
					border-radius: 20px;
					line-height: 40px;
					font-size: 16px;
					color: $fontColor;
					text-align: center;
					cursor: pointer;

					&.active {
						background-color: #FFF1E2;
						color: $mainColor;
					}
				}
			}

			.panelTable {
				height: calc(100% - 112px);
				width: 100%;
			}
		}
	}
}
</style>
